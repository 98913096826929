import React from "react"
import Image from "next/image"
import { Card } from "react-bootstrap"

function Employees(props) {
    return (
        <>
            <div className="employee-img-div">
                <Image
                    src={`https://webbjenkinsstatic.imgix.net/employees/${props.image}`}
                    alt={props.alt}
                    layout="fill"
                    objectFit="cover"
                    objectPosition="center"
                    sizes="50vw"
                />
            </div>

            <Card
                className={
                    props.name === "Steve Webb" || props.name === "Mark Jenkins"
                        ? "mt-2 mb-4 mb-xl-0"
                        : props.name === "Olivia Jenkins"
                        ? "mt-2 mb-4 mb-sm-0"
                        : "mt-2"
                }
            >
                <Card.Title className="py-3">
                    <p className="text-semi-bold text-center mb-0 ">
                        {props.name.split(" ")[0]}
                    </p>
                </Card.Title>
            </Card>
        </>
    )
}

export default Employees
