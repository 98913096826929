import React from "react"
import Link from "next/link"
import Image from "next/image"
import { Card, Row, Col } from "react-bootstrap"

function SmallBlock(props) {
    const scrollToTop = () => {
        if (typeof window !== "undefined") {
            window.scrollTo(0, 0)
        }
    }

    return (
        <Col
            xs={12}
            sm={6}
            md={4}
            className={
                props.title === "Sellers"
                    ? "overflow-hidden px-sm-0 mb-4 pe-sm-3"
                    : props.title === "Buyers"
                    ? "overflow-hidden px-sm-0 mb-4 ps-sm-3 ps-md-2 pe-md-2"
                    : "overflow-hidden px-sm-0 px-sm-2 px-md-0 mb-4 ps-md-3"
            }
        >
            <Link
                href={
                    props.title === "Property services"
                        ? "/property-services"
                        : "/" + props.title.toLowerCase()
                }
                className="d-inline"
            >
                <a onClick={scrollToTop()}>
                    <Card className="p-0">
                        <div className="specific-image h-100">
                            <Image
                                src={`https://webbjenkinsstatic.imgix.net/heroes/${props.image}`}
                                alt={props.alt}
                                layout="fill"
                                objectFit="cover"
                                objectPosition="center"
                                sizes="50vw"
                            />
                            <Row className="mx-0 h-100 align-items-end specific-image-text">
                                <Col className=" ms-4 mb-3 z-index-1">
                                    <span className="h4 text-white">
                                        {props.title}
                                    </span>
                                </Col>
                            </Row>
                        </div>
                    </Card>
                </a>
            </Link>
        </Col>
    )
}

export default SmallBlock
