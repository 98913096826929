import React from "react"
import Head from "next/head"
import Link from "next/link"
import { Container, Card, Row, Col, Button } from "react-bootstrap"

import HomeHeroImage from "@/home/homeHeroImage"
import PropertySearch from "@/forms/propertySearch"
import Welcome from "@/home/welcome"
import SmallBlock from "@/home/smallBlock"
import LargeBlock from "@/home/largeBlock"
import ReviewCarousel from "@/home/reviewCarousel"
import Employees from "@/home/employees"
import LogoBanner from "@/home/logoBanner"

function HomeScreen() {
    const scrollToTop = () => {
        if (typeof window !== "undefined") {
            window.scrollTo(0, 0)
        }
    }

    const url = "https://webbjenkins.co.uk/"
    const title = "Webb Jenkins - Isle of Wight Estate Agents & Lettings Agents"
    const description =
        "Trusted estate agents based in Ventnor, Isle of Wight. We specialise in all areas of property sales, lettings & maintenance across Ventnor, Shanklin & Sandown."
    const imgUrl =
        "https://webbjenkinsstatic.imgix.net/socials/webbjenkins-social.jpg"
    const imgWidth = 1200
    const imgHeight = 630
    const imgAlt = "Webb Jenkins logo"

    return (
        <>
            <Head>
                <link rel="canonical" href={url} />
                <title>{title}</title>
                <meta name="description" content={description} />

                <meta property="og:title" content={title} />
                <meta property="og:image:url" content={imgUrl} />
                <meta property="og:image:width" content={imgWidth} />
                <meta property="og:image:height" content={imgHeight} />
                <meta property="og:image:alt" content={imgAlt} />
                <meta property="og:description" content={description} />
                <meta property="og:url" content={url} />
                <meta property="og:type" content="website" />
                <meta property="og:locale" content="en-GB" />

                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:image:src" content={imgUrl} />
                <meta name="twitter:site" content="@WebbJenkinsIOW" />
            </Head>
            <HomeHeroImage />
            <PropertySearch title="Find your new home" />

            <Container fluid="md" as={Card} className="p-0 mt-5">
                <Card.Body>
                    <Card.Title>
                        <h2 className="text-center">Welcome to Webb Jenkins</h2>
                    </Card.Title>
                </Card.Body>
            </Container>
            <Container fluid="md" as={Card} className="p-0 mt-4 ">
                <Card.Body>
                    <Card.Text className="text-center">
                        <span>
                            Welcome to Webb Jenkins estate and lettings agents.
                            Our dedicated team handle all aspects of property
                            sales, lettings and maintenance across the Isle of
                            Wight. We are specialists in what we do and we are
                            here to help you. Whatever you are looking for,
                            whether it be a new home, tenant, buyer or
                            tradesman, we can help find the perfect match for
                            you. Our team of friendly staff are always happy to
                            help, so do not hesitate to get in touch or pop down
                            to our Ventnor based office to discuss next steps.
                        </span>
                    </Card.Text>
                </Card.Body>
            </Container>
            <Welcome />
            <Container fluid="md" as={Card} className="p-0 mt-5">
                <Card.Body>
                    <Card.Title>
                        <h2 className="text-center">
                            Find specific information
                        </h2>
                    </Card.Title>
                </Card.Body>
            </Container>

            <Container fluid="md" className="p-0 mt-4">
                <Row className="mx-0 justify-content-center">
                    <SmallBlock
                        title="Sellers"
                        image="property-seller-relaxing.jpg"
                        alt="Woman who is selling her property relaxing on the sofa smiling, checking her phone"
                    />

                    <SmallBlock
                        title="Buyers"
                        image="couple-buy-property.jpg"
                        alt="Excited couple showing off the keys to their new home"
                    />
                    <SmallBlock
                        title="Property services"
                        image="tradesman-measuring-job.jpg"
                        alt="Professional tradesman measuring up the wall for a job"
                    />
                </Row>
                <Row className="mx-0">
                    <LargeBlock
                        title="Landlords"
                        image="landlords-relaxing.jpg"
                        alt="Landlords relaxing on the sofa smiling, while checking their property online"
                    />
                    <LargeBlock
                        title="Tenants"
                        image="couple-rent-property.jpg"
                        alt="A couple having fun messing around with house moving boxes after renting a property"
                    />
                </Row>
            </Container>

            <Container fluid="md" as={Card} className="mt-5 p-0">
                <Card.Body>
                    <Card.Title>
                        <h2 className="text-center">
                            See what our customers have to say about us
                        </h2>
                    </Card.Title>
                </Card.Body>
            </Container>

            <Container fluid="md" as={Card} className="p-0 mt-4">
                <Card.Body>
                    <ReviewCarousel />
                </Card.Body>
            </Container>

            <Container fluid="md" as={Card} className="mt-5 p-0">
                <Card.Body>
                    <Card.Title>
                        <h2 className="text-center">Our team</h2>
                    </Card.Title>
                </Card.Body>
            </Container>

            <Container fluid="md" className="p-0 mt-4">
                <Row className="mx-0">
                    <Col xs={12} sm={6} xl={4} className="p-0 pe-sm-3 pe-xl-4">
                        <Employees
                            name="Steve Webb"
                            image="steve.jpg"
                            alt="Steve Webb, member of the Webb Jenkins team"
                        />
                    </Col>

                    <Col
                        xs={12}
                        sm={6}
                        xl={4}
                        className="p-0 ps-sm-3 ps-xl-2 pe-xl-3"
                    >
                        <Employees
                            name="Mark Jenkins"
                            image="mark.jpg"
                            alt="Mark Jenkins, member of the Webb Jenkins team"
                        />
                    </Col>

                    <Col
                        xs={12}
                        sm={6}
                        xl={4}
                        className="p-0 pe-sm-3 pe-xl-2 ps-xl-3 mx-auto"
                    >
                        <Employees
                            name="Olivia Jenkins"
                            image="olivia.jpg"
                            alt="Olivia Jenkins, member of the Webb Jenkins team"
                        />
                    </Col>

                    {/* <Col xs={12} sm={6} xl={3} className="p-0 ps-sm-3 ps-xl-4">
                        <Employees
                            name="Beckie Aubert"
                            image="beckie.jpg"
                            alt="Beckie Aubert, member of the Webb Jenkins team"
                        />
                    </Col> */}
                </Row>
            </Container>

            <Container fluid="md" className="mt-4">
                <Row className="text-center justify-content-center">
                    <Link href="/our-team" passHref>
                        <Button
                            onClick={scrollToTop()}
                            size="lg"
                            variant="warning"
                            className="w-auto px-4 rounded-0 mb-0"
                        >
                            <span className="m-0 text-white">
                                Find out more
                            </span>
                        </Button>
                    </Link>
                </Row>
            </Container>

            <Container fluid="md" as={Card} className="mt-5 mb-6 ">
                <Card.Body>
                    <Row className="justify-content-around align-items-center">
                        <LogoBanner />
                    </Row>
                </Card.Body>
            </Container>
        </>
    )
}

export default HomeScreen
