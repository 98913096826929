import React from "react"

import { Container, Card, Row, Col } from "react-bootstrap"
import WelcomeSquare from "@/home/welcomeSquare"

function Welcome() {
    return (
        <>
            <Container fluid="md" as={Card} className="p-0 mt-5">
                <Card.Body>
                    <Card.Title>
                        <h2 className="text-center">
                            What makes us different?
                        </h2>
                    </Card.Title>
                </Card.Body>
            </Container>

            <Container fluid="md" className="p-0 mt-4">
                <Row className="mx-0 justify-content-center">
                    <Col
                        xs={12}
                        sm={6}
                        lg={4}
                        className="mb-4 mb-lg-0 p-0 pe-sm-3 pe-lg-4"
                    >
                        <WelcomeSquare
                            icon="independent-icon.svg"
                            alt="Independent business icon"
                            title="Independent"
                            description="Proud to be one of only a few remaining truly independent agents"
                        />
                    </Col>

                    <Col
                        xs={12}
                        sm={6}
                        lg={4}
                        className="mb-4 mb-lg-0 p-0 ps-sm-3 ps-lg-2 pe-lg-2"
                    >
                        <WelcomeSquare
                            icon="local-icon.svg"
                            alt="Local business icon"
                            title="Local based"
                            description="Local office enabling us to carry out viewings and appraisals promptly"
                        />
                    </Col>

                    <Col
                        xs={12}
                        sm={6}
                        lg={4}
                        className="p-0 px-sm-2 px-lg-0 ps-lg-4"
                    >
                        <WelcomeSquare
                            icon="together-icon.svg"
                            alt="Business sectors working together icon"
                            title="Sales & rentals"
                            description="The only local agent offering both sales and lettings from the same office"
                        />
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default Welcome
