import React, { useState } from "react"

import { Collapse, Row, Col, Button } from "react-bootstrap"

function Review(props) {
    const [open, setOpen] = useState(false)

    const remaining = props.review.length - 200

    return (
        <Col xs={12} xl={6}>
            <Row className="h-100 align-items-center my-auto">
                <Col className="py-5 px-5 px-sm-7 px-lg-8 px-xxl-9">
                    <p className="d-inline">
                        <i>
                            &ldquo;{props.review.slice(0, 200)}
                            {!open && "..."}
                        </i>
                        <Collapse in={open}>
                            <i id={props.revId}>
                                {props.review.slice(0 - remaining)}&rdquo;
                            </i>
                        </Collapse>
                    </p>

                    <Row className="mt-2">
                        <Button
                            onClick={() => setOpen(!open)}
                            aria-controls={props.revId}
                            aria-expanded={open}
                            variant="outline-warning"
                            className="py-0 m-0  rounded-0"
                            style={{ border: "none" }}
                        >
                            <span className="text-sm text-wjorange">
                                {!open ? "show more" : "show less"}
                            </span>
                        </Button>
                    </Row>

                    <p className="h5 text-end">{props.reviewer}</p>
                </Col>
            </Row>
        </Col>
    )
}

export default Review
