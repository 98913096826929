import React from "react"
import Review from "@/home/review"

import { Carousel, Row } from "react-bootstrap"

function ReviewCarousel() {
    return (
        <>
            <Carousel
                interval={10000}
                variant="dark"
                className="d-xl-none review"
                keyboard
            >
                <Carousel.Item className="review">
                    <Review
                        revId="TyNik1"
                        reviewer="Tyler & Nikki"
                        review="We have used Webb Jenkins a number of times over the last 20 years, they have always gone over and above our expectations and nothing is ever too much trouble for them. We have confidently recommended them to friends and family who have been equally impressed by their efforts to put together a successful sale with regular updates and advice."
                    />
                </Carousel.Item>
                <Carousel.Item className="review">
                    <Review
                        revId="JanTony1"
                        reviewer="Jan & Tony"
                        review="For a person that had managed my own rental properties for a number of years, what a breath of fresh air it was handing over my rental properties to be managed by Webb Jenkins. I can now relax knowing that I’m in safe hands and that I am complying with all the necessary regulations & requirements, can now live stress free knowing that the tenants are also being well looked after."
                    />
                </Carousel.Item>
                <Carousel.Item className="review">
                    <Review
                        revId="Barb1"
                        reviewer="Barbara"
                        review="Buying through Steve & Mark at Webb Jenkins was a smooth and pleasant process. They gave me regular updates of new properties and rang to say that they have a property that is new to their books that appears to be just what we were looking for. How right they were, it was my dream home and went on to purchase in what I can only say was the smoothest property purchase that I have ever been involved in, well done team Webb Jenkins."
                    />
                </Carousel.Item>
                <Carousel.Item className="review">
                    <Review
                        revId="Deb1"
                        reviewer="Debbie"
                        review="Thank you Beckie & Olivia for helping me rent my first ever property. It was a very daunting prospect having to find a property to rent for the time, but I need not have worried, you looked after every aspect of the process for me, explained everything well in simple terms which was brilliant. Thank you and will certainly be recommending you to family and friends if they need help."
                    />
                </Carousel.Item>
            </Carousel>

            <Carousel
                interval={10000}
                variant="dark"
                className="d-none d-xl-flex review mt-2"
            >
                <Carousel.Item className="review">
                    <Row>
                        <Review
                            revId="TyNik2"
                            reviewer="Tyler & Nikki"
                            review="We have used Webb Jenkins a number of times over the last 20 years, they have always gone over and above our expectations and nothing is ever too much trouble for them. We have confidently recommended them to friends and family who have been equally impressed by their efforts to put together a successful sale with regular updates and advice."
                        />
                        <Review
                            revId="JanTony2"
                            reviewer="Jan & Tony"
                            review="For a person that had managed my own rental properties for a number of years, what a breath of fresh air it was handing over my rental properties to be managed by Webb Jenkins. I can now relax knowing that I’m in safe hands and that I am complying with all the necessary regulations & requirements, can now live stress free knowing that the tenants are also being well looked after."
                        />
                    </Row>
                </Carousel.Item>
                <Carousel.Item className="review">
                    <Row>
                        <Review
                            revId="Barb2"
                            reviewer="Barbara"
                            review="Buying through Steve & Mark at Webb Jenkins was a smooth and pleasant process. They gave me regular updates of new properties and rang to say that they have a property that is new to their books that appears to be just what we were looking for. How right they were, it was my dream home and went on to purchase in what I can only say was the smoothest property purchase that I have ever been involved in, well done team Webb Jenkins."
                        />
                        <Review
                            revId="Deb2"
                            reviewer="Debbie"
                            review="Thank you Beckie & Olivia for helping me rent my first ever property. It was a very daunting prospect having to find a property to rent for the time, but I need not have worried, you looked after every aspect of the process for me, explained everything well in simple terms with was brilliant. Thank you and will certainly be recommending you to family in friends if they need help."
                        />
                    </Row>
                </Carousel.Item>
            </Carousel>
        </>
    )
}

export default ReviewCarousel
