import React from "react"
import Image from "next/image"
import { Col } from "react-bootstrap"

function Logo(props) {
    return (
        <Col xs={12} sm={6} md={4} xl={2} className="px-3 py-3">
            <div className="logo-image">
                <Image
                    src={`https://webbjenkinsstatic.imgix.net/logos/${props.image}`}
                    alt={props.alt}
                    layout="fill"
                    objectFit="contain"
                    objectPosition="center"
                    sizes="25vw"
                />
            </div>
        </Col>
    )
}

export default Logo
