import React from "react"
import Image from "next/image"

import { Card } from "react-bootstrap"

function WelcomeSquare(props) {
    return (
        <Card className="p-4 h-100">
            <div className="icon-div">
                <Image
                    src={`https://webbjenkinsstatic.imgix.net/icons/${props.icon}`}
                    alt={props.alt}
                    layout="fill"
                    objectPosition="center"
                />
            </div>

            {/* <Card.Img src={props.icon} alt="Icon" height="50rem" /> */}
            <Card.Title>
                <h3 className="h5 mt-3 text-center">{props.title}</h3>
            </Card.Title>
            <Card.Text className="text-center">
                <span>{props.description}</span>
            </Card.Text>
        </Card>
    )
}

export default WelcomeSquare
