import React from "react"

import Logo from "@/home/logo"

function LogoBanner() {
    return (
        <>
            <Logo image="ombudsman.png" alt="Property Ombudsman logo" />
            <Logo
                image="legal-for-landlords.jpg"
                alt="Legal for Landlords logo"
            />

            <Logo image="trading-standards.jpg" alt="Trading Standards logo" />
            <Logo
                image="gdpr.png"
                alt="General Data Protection Regulation logo"
            />
            <Logo image="dps.jpg" alt="Deposit Protection Scheme logo" />
        </>
    )
}

export default LogoBanner
