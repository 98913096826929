import React from "react"
import Image from "next/image"
import Typewriter from "typewriter-effect"
import { useDispatch } from "react-redux"

import { Container, Row, Col, Button } from "react-bootstrap"

import { toggleValuationModal } from "@/slices/valuationModalSlice"

function HomeHeroImage() {
    const dispatch = useDispatch()
    return (
        <>
            {/* Hero Image */}
            <Container fluid className="p-0 overflow-hidden">
                <Row className="m-0 gx-0">
                    <div className="home-parallax">
                        <Image
                            src="https://webbjenkinsstatic.imgix.net/heroes/ventnor-harbour-isle-of-wight.jpg"
                            alt="Ventnor, Isle of Wight seafront with Webb Jenkins orange brand colours as an overlay"
                            layout="fill"
                            objectFit="cover"
                            objectPosition="center"
                            className="background-image"
                            priority
                        />
                        <div className="wjoverlay">
                            <Container fluid="md">
                                <Row className="gx-0 align-items-center justify-content-center home-parallax-content">
                                    <Col
                                        xs={12}
                                        xxl={10}
                                        className="py-3 px-lg-10"
                                    >
                                        <span className="h1 text-center text-white px-2 px-lg-0 d-flex justify-content-center">
                                            Are you looking to
                                            <div className="typewritter-desktop text-start">
                                                <Typewriter
                                                    options={{
                                                        wrapperClassName:
                                                            "h1 text-white",
                                                        cursorClassName:
                                                            "Typewriter__cursor h1 text-white",
                                                        strings: [
                                                            "buy?",
                                                            "sell?",
                                                            "rent?",
                                                            "let?",
                                                        ],
                                                        pauseFor: 1000,
                                                        autoStart: true,
                                                        loop: true,
                                                    }}
                                                />
                                            </div>
                                        </span>
                                        <span className="h1 w-100 typewritter-mobile text-white text-center px-2 px-lg-0 justify-content-center">
                                            <div className="text-center w-100">
                                                <Typewriter
                                                    options={{
                                                        wrapperClassName:
                                                            "h1 text-white",
                                                        cursorClassName:
                                                            "Typewriter__cursor h1 text-white",
                                                        strings: [
                                                            "buy?",
                                                            "sell?",
                                                            "rent?",
                                                            "let?",
                                                        ],
                                                        pauseFor: 1000,
                                                        autoStart: true,
                                                        loop: true,
                                                    }}
                                                />
                                            </div>
                                        </span>
                                        <h1 className="span text-normal text-lg text-white text-center px-2 px-lg-0 d-flex justify-content-center">
                                            We&apos;ve got you covered. The
                                            trusted Isle of Wight estate and
                                            lettings agents for all your
                                            property needs
                                        </h1>
                                        <div className="w-100 text-center hero-btn mt-4">
                                            <Button
                                                size="lg"
                                                variant="bg-white"
                                                className="px-3 px-xl-4 bg-white"
                                                onClick={() =>
                                                    dispatch(
                                                        toggleValuationModal(
                                                            true,
                                                        ),
                                                    )
                                                }
                                            >
                                                <span className="p-0 text-warning text-bold">
                                                    Get a free valuation
                                                </span>
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </div>
                </Row>
            </Container>
            {/* Hero Image End */}
        </>
    )
}

export default HomeHeroImage
